import { useAsyncData } from "nuxt/app";
import { defineStore } from "pinia";
import { useSsrFetch } from "../composables/useFetch";
import { useProjectsFiltersStore } from '~/stores/projects-filters.store';
import type { Project } from "~/types/project.type";
import type { Country } from "~/types/country.type";
import type { Pagination } from "~/types/pagination.type";

export const useProjectsStore = defineStore("projects", {
	state: () => ({
		projects: {} as {
			projects: Project[];
			country: Country;
			pagination: Pagination;
		},
	}),
	actions: {
		async fetchProjects(route: { query: any, params: any }) {
			const filtersStore = useProjectsFiltersStore();

			const page = route.query.page || 1;
			const slug = encodeURIComponent(btoa(route.params.location as string));
			route.params['filters'] = route.query as any || {}
			const qs = encodeURIComponent(btoa(JSON.stringify(route.params)));

			const result: any = await useAsyncData(
				'projects',
				() => useSsrFetch(`pages/projects?page=${page}&slug=${slug}&qs=${qs}`),
			);

			this.projects = result?.data.value;

			const country = result?.data.value?.data?.country;
			if (country) {
				filtersStore.addCustomQuery('location', 'country_id', country.country_id || country._id);
			}

			return this.projects;
		},
		async fetchCountriesHasProjects(): Promise<Banner[]> {
			return await useSsrFetch('data/projects/countries') as Banner[] || [];
		},
	},
});
